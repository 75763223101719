import { Transforms } from "slate";
import insertNewLine from "./insertNewLine";
import scrollToNewSection from "../helper/RnD/scrollToNewSection";

const Default_text_Node = {
  type: "paragraph",
  children: [{ text: "Effortless Business Growth" }],
};

export const insertFreeGridItem = (
  childType,
  childTypeNode,
  extProps = {},
  type = "freegridItem"
) => {
  return {
    type: type || "freegridItem",
    childType: childType || "text",
    children: [childTypeNode || Default_text_Node],
    gridArea: "1 / 1 / 2 / 2",
    height: 300,
    width: 250,
    left: 401,
    top: 0,
    marginTop: 47,
    left_xs: 24,
    top_xs: 24,
    marginTop_xs: 12,
    width_xs: 250,
    height_xs: 300,
    gridArea_xs: "1 / 1 / 2 / 2",
    sectionBgColor: "rgb(240, 108, 131)",
    ...(extProps || {}),
  };
};

export const insertFreeGrid = (editor, path, extProps) => {
  const freegridData = [
    {
      type: "freegrid",
      height: 508,
      gridTemplateRows: "var(--rows)",
      gridTemplateColumns: "var(--cols)",
      children: [
        {
          type: "freegridItem",
          childType: "text",
          children: [
            {
              type: "alignCenter",
              children: [
                {
                  type: "paragraph",
                  children: [
                    {
                      text: "Heading One",
                      fontSize: {
                        xs: "38px",
                        sm: "54px",
                        md: "54px",
                        lg: "54px",
                      },
                      fontFamily: "PoppinsBold",
                    },
                  ],
                },
              ],
            },
          ],
          gridArea: "2 / 1 / 3 / 2",
          height: 88,
          width: 746,
          left: 129,
          marginTop: 23,
          top_xs: 105.1875,
          left_xs: 22,
          marginTop_xs: 38,
          width_xs: 272,
          height_xs: 163.0078125,
          gridArea_xs: "2 / 1 / 3 / 2",
          updated_at: 1728554109479,
          lg_updatedOn: 1726290562773,
          xs_updatedOn: 1728554109478,
        },
      ],
      height_xs: 593.0625,
      updated_at: 1728554109479,
      width: 0,
      sectionBgColor: "rgb(222, 99, 138)",
      xs_updatedOn: 1727921628092,
      sectionName: "Home",
    },
  ];
  const newPath = path ? path : editor?.selection?.anchor?.path;
  let upNode = [...freegridData];
  if (path) {
    upNode = [
      {
        type: "paragraph",
        children: upNode,
      },
    ];
  }
  Transforms.insertNodes(editor, upNode, {
    at: newPath,
  });
  scrollToNewSection(editor, newPath, extProps);
  insertNewLine(editor);
};
