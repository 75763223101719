import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import useVirtualElementStyles from "./styles";
import updateAutoProps from "./updateAutoProps";
import { calculateGridArea } from "../Utils/gridDropItem";

const ROOT_ITEM_CLASS = ".freegrid-item.path-3";

const VirtualElement = (props) => {
  const classes = useVirtualElementStyles();
  const { editor, path, parentEle, updated_at } = props;
  const cloneNode = parentEle?.cloneNode(true);
  const virtualRef = useRef();

  useEffect(() => {
    if (virtualRef?.current) {
      setTimeout(() => {
        calculateProps();
      }, 0);
    }
  }, [updated_at, virtualRef?.current]);

  const calculateProps = () => {
    const rect = virtualRef?.current?.getBoundingClientRect();
    const sectionProps = {
      path,
      props: {
        height: rect.height,
      },
    };
    const itemsData = [];
    const items = virtualRef?.current?.querySelectorAll(ROOT_ITEM_CLASS);
    let sectionHeight = 0;
    for (let i = 0; i < items.length; i++) {
      const itemRect = items[i]?.getBoundingClientRect();
      const y = Math.abs(rect.top - itemRect?.top);
      itemsData.push({
        path: items[i]?.dataset.path,
        props: {
          top: y,
          left: 24,
          marginTop: 12,
          width: itemRect?.width,
          height: itemRect?.height,
          gridArea: calculateGridArea(y),
        },
      });
      sectionHeight += itemRect?.height;
    }
    if (sectionHeight > sectionProps?.props?.height) {
      sectionProps.props.height = sectionHeight;
    }
    const allData = [sectionProps, itemsData]?.flat();
    // it should trigger by auto alignment or on clicking mobile view change
    updateAutoProps(editor, allData, "xs");
  };

  const getItems = () => {
    const items = cloneNode?.querySelectorAll(ROOT_ITEM_CLASS) || [];
    let itemsHTML = "";
    for (let i = 0; i < items?.length; i++) {
      items[i].classList.add("exclude-virtual");
      itemsHTML += items[i].outerHTML;
    }
    return itemsHTML;
  };

  return (
    <Box
      className={"mobile-virtual-mode"}
      ref={virtualRef}
      sx={classes.root}
      dangerouslySetInnerHTML={{ __html: getItems() }}
    />
  );
};

export default VirtualElement;
