const useOptionsPopupStyle = () => ({
  root: {
    zIndex: 1200,
    background: "#FFF",
    borderRadius: "5px",
    overflow: "hidden",
    boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
    "& .papper-root": {
      borderTop: "8px solid #1976d2",
      width: "270px",
      boxShadow: "none",
    },
    "& .item-list-wrpr": {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    "& .item-wrapper": {
      padding: "12px",
      fontFamily: "sans-serif",
      "&.title": {
        display: "flex",
        fontWeight: "bold",
        justifyContent: "space-between",
        alignItems: "center",
        "& .MuiIconButton-root": {
          padding: "4px",
          "& svg": {
            width: "16px",
            height: "16px",
          },
        },
      },
    },
  },
});

export default useOptionsPopupStyle;
