const useVirtualElementStyles = () => ({
  root: {
    width: "320px",
    position: "fixed",
    backgroundColor: "red",
    height: "auto",
    overflow: "visible",
    pointerEvents: "none",
    opacity: 0,
    right: 0,
    top: 0,
    "& .freegrid-item": {
      gridArea: "none !important",
      width: "calc(100% - 48px) !important",
      height: "auto !important",
      left: "24px !important",
      marginTop: "24px !important",
      "& .debug-info, & .editor-blocker": {
        display: "none",
      },
      "& .embed": {
        minHeight: "300px",
      },
    },
  },
});

export default useVirtualElementStyles;
