import TextSettings from "./TextSettings";
import ButtonSettings from "./ButtonSettings";
import ImageSettings from "./ImageSettings";
import VideoSettings from "./VideoSettings";
import BoxSettings from "./BoxSettings";
import AppHeaderSettings from "./AppHeaderSettings";
import FormSettings from "./FormSettings";

const SettingsComponents = {
  text: TextSettings,
  button: ButtonSettings,
  image: ImageSettings,
  video: VideoSettings,
  box: BoxSettings,
  appHeader: AppHeaderSettings,
  form: FormSettings,
};

export default SettingsComponents;
