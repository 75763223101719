import React, { useState } from "react";
import { Transforms, Node, Path } from "slate";
import { useSlateStatic } from "slate-react";
import { ReactEditor } from "slate-react";
import { Box, useTheme } from "@mui/material";
import RnD from "../../common/RnD";
import { onDropItem } from "../../common/RnD/Utils/gridDropItem";
import useBreakpoints from "../../hooks/useBreakpoints";
import {
  breakpointValues,
  formatBreakpointValues,
} from "./breakpointConstants";
import { useEditorContext } from "../../hooks/useMouseMove";
import focusOnNewItem from "../../helper/RnD/focusOnNewItem";
import { bringItemToFB } from "../../helper";
import itemOptions from "./Options/sectionItemOptions";
import { formField } from "../../utils/formfield";
import Workflow from "../Form/Workflow";

const FreeGridItem = (props) => {
  const theme = useTheme();
  const breakpoint = useBreakpoints(theme);
  const { element, attributes, children, customProps } = props;
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);
  const { readOnly } = customProps;
  const { updated_at, childType, zIndex } = element;
  // get values based on breakpoint size
  const { gridArea, width, height, left, top, marginTop } = breakpointValues(
    element.type,
    breakpoint,
    element
  );
  const { setSelectedElement, selectedElement } = useEditorContext();
  const arrangeIndex = zIndex === undefined ? path[path.length - 1] : zIndex;

  const [openWorkflow, setOpenWorkflow] = useState(false);

  const onChangeSettings = () => { };

  const onChange = (data) => {
    let updateData = { ...data };
    delete updateData.children;
    updateData = formatBreakpointValues(element.type, breakpoint, updateData);
    Transforms.setNodes(
      editor,
      {
        ...updateData,
      },
      {
        at: path,
      }
    );
  };

  const handleDragEvent = (type, data, cb) => {
    const toPosition = {
      left: left + data.lastX,
      top: top + data.lastY,
    };
    const { node, ...rest } = data;
    switch (type) {
      case "stop":
        const updated_data = onDropItem(
          {
            editor,
            fromPosition: { left, top },
            toPosition,
            path,
            ref: node,
            breakpoint,
            ...rest,
          },
          ".freegrid-container"
        );
        if (cb) {
          cb(updated_data);
        }
        break;
      default:
    }
  };

  const handleResizeEvent = (type, data, cb) => {
    switch (type) {
      case "stop":
        return;
      default:
    }
  };

  const onDelete = () => {
    try {
      Transforms.removeNodes(editor, { at: path });
    } catch (err) {
      console.log(err);
    }
  };

  const handleContextMenuClick = (d) => {
    switch (d?.name) {
      case "bringForward":
        bringItemToFB(editor, { path, moveFront: true });
        break;
      case "bringBackward":
        bringItemToFB(editor, { path, moveFront: false });
        break;
      case "cut":
        window.copiedNode = JSON.stringify(Node.get(editor, path));
        onDelete();
        focusOnNewItem(editor, Path.parent(path), {
          setSelectedElement,
        });
        break;
      case "copy":
        window.copiedNode = JSON.stringify(Node.get(editor, path));
        break;
      case "delete":
        onDelete();
        setSelectedElement({});
        break;
      default:
        return;
    }
  };

  const onAddFormField = () => {
    try {
      Transforms.insertNodes(editor, { ...formField() }, { at: [...path, 0, 0] });
    } catch (err) {
      console.log(err, "Add Field Error in Form Builder");
    }
  };

  const closeWorkflow = () => {
    setOpenWorkflow(false);
  };

  const onWorkflowSave = (data) => {
    const updateData = { ...data };
    delete updateData.children;
    Transforms.setNodes(
      editor,
      {
        ...updateData,
      },
      {
        at: [...path, 0],
      }
    );
  };

  const handleActionClick = (type) => {
    switch (type) {
      case "workFlow":
        setSelectedElement({
          ...selectedElement,
          enable: 2,
        })
        setOpenWorkflow(true);
        break;
      case "addFormField":
        onAddFormField()
        break;
      default:
        break;
    }
  };

  return (
    <RnD
      id={`freegrid_item_${path.join("|")}_${updated_at}_${breakpoint}`}
      className={`freegrid-item path-${path.length} breakpoint-${breakpoint}`}
      editor={editor}
      path={path}
      actions={itemOptions[childType] || itemOptions?.default}
      settingsProps={{
        settings: "freegridItem",
        onChange: onChangeSettings,
        customProps,
      }}
      style={{
        "--left": `${left || 0}px`,
        "--marginTop": `${marginTop || 0}px`,
        "--gridArea": `${gridArea}`,
        "--width": `${width}px`,
        "--height": `${height}px`,
        "--zIndex": 100 + arrangeIndex,
      }}
      defaultStyle={{
        height: `${height}px`,
        width: `${width}px`,
      }}
      gridArea={gridArea}
      onChange={onChange}
      delta={{ width, height }}
      handleDragEvent={handleDragEvent}
      handleResizeEvent={handleResizeEvent}
      readOnly={readOnly}
      childType={childType || ""}
      handleContextMenuClick={handleContextMenuClick}
      handleActionClick={handleActionClick}
      itemData={{ left: left || 0 }}
    >
      <Box
        component={"div"}
        {...attributes}
        className={`fgi_type_${childType}`}
      >
        {children}
        <Workflow
          openWorkflow={openWorkflow}
          element={element}
          customWorkflowElement={element?.children?.[0] || null}
          closeWorkflow={closeWorkflow}
          onSave={onWorkflowSave}
        />
      </Box>
      {/* <Box className={"debug-info"} contentEditable={false}>
        <b>Debug Info:</b> <br />
        Path: {path.join("|")} <br />
        Grid Area: {gridArea}
      </Box> */}
    </RnD>
  );
};

export default FreeGridItem;
