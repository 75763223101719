const editorStyles = ({ padHeight, placeHolderColor, theme }) => ({
  root: {
    display: "flex",
    position: "relative",
    padding: "0px",
    alignItems: "center",
    justifyContent: "center",
    "*": {
      boxSizing: "border-box",
    },
    "&.iframe-editor": {
      "& .mini-tool-wrpr-ei": {
        display: "none",
      },
    },

    "& .mobileMiniTextWrapper": {
      boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.16)",
      position: "fixed",
      bottom: "env(safe-area-inset-bottom)",
      left: 0,
      width: "100%",
      zIndex: 99999,
      backgroundColor: theme?.palette?.editor?.background || "#fff",
      color: theme?.palette?.editor?.textColor || "black",

      "& .MuiOutlinedInput-input": {
        color: theme?.palette?.editor?.textColor || "black",
      },
    },
    "&.stimulate-xs": {
      "& .scrollable-content": {
        width: "320px",
      },
      ":before": {
        content: '" "',
        position: "absolute",
        top: 0,
        left: 0,
        width: "calc(50% - 162px)",
        height: "100%",
        pointerEvents: "none",
        backgroundColor: "#FFF",
        zIndex: 10,
      },
      ":after": {
        content: '" "',
        position: "absolute",
        top: 0,
        right: 0,
        width: "calc(50% - 160px)",
        height: "100%",
        pointerEvents: "none",
        backgroundColor: "#FFF",
        zIndex: 10,
      },
    },
  },
  slateWrapper: {
    paddingTop: "0px",
    height: `${window.innerHeight - padHeight}px`,
    width: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#0f172a",
    "& .has-topbanner": {
      "& .doc-title-ele-wrpr": {
        marginTop: "12px",
      },
    },
    "&.no-color": {
      backgroundColor: theme?.palette?.editor?.background,
      color: theme?.palette?.editor?.textColor,
      "& .signed-btn": {
        "& img": {
          backgroundColor: "#FFF",
        },
      },
    },
    "& .max-content": {
      width: "100%",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      paddingBottom: "25px !important",
    },
    "& .scroll-area": {
      display: "flex",
      justifyContent: "center",
      flex: 1,
    },
    "& .editor-wrapper": {
      maxWidth: "100%",
      height: "100%",
      overflow: "visible",
      "& .section-wrapper-fluid": {
        "& .grid-container": {
          maxWidth: "1440px",
        },
      },
    },
    "& .el-toolbar": {
      position: "absolute",
      left: 0,
      top: 0,
      width: "fit-content",
      "& button": {
        border: "1px solid rgba(37, 99, 235, 0.32)",
        borderRadius: "10px",
        boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.16)",
        background: "#fff",
        width: "36px",
        height: "36px",
        "&.mr": {
          marginRight: "2px",
        },
        "& svg": {
          stroke: "rgb(100, 116, 139);",
        },
      },
    },
    "& .accordion-summary-collapse-btn": {
      padding: "4px",
    },
    "& .workflow-icon-btn": {
      pointerEvents: "none",
      position: "absolute",
      right: "-9px",
      top: "-9px",
      border: "2px solid #f3b814",
      padding: "0px",
      background: "#FFF",
      "& svg": {
        width: "18px",
        height: "18px",
      },
    },
    "& .svg-big-btn": {
      padding: "2px !important",
      "& svg": {
        width: "24px !important",
        height: "24px !important",
      },
    },
    "& .ed-section-wrapper": {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      "&:hover": {
        "& .ed-section-inner": {
          "& .element-toolbar.ss": {
            display: "block",
            left: "8px",
            top: "8px",
            width: "fit-content",
            "& button": {
              boxShadow: "none",
              color: "#D7D7D6",
              background: "rgb(221, 221, 221, 0.1)",
              "&:hover": {
                background: "rgb(221, 221, 221, 0.8)",
              },
            },
          },
        },
        "&.hselect:before": {
          display: "block",
        },
      },
      "& .ed-section-inner": {
        maxWidth: "1440px",
        "& .element-toolbar.ss": {
          display: "none",
        },
      },
      "&.needHover:before": {
        content: '" "',
        position: "absolute",
        width: "calc(100% - 2px)",
        height: "calc(100% - 2px)",
        left: 0,
        top: 0,
        border: "1px solid transparent",
        background: "rgba(0,0,0,0.05)",
        pointerEvents: "none",
        display: "none",
      },
    },
    "& .element-empty-btn": {
      backgroundColor: "rgb(242, 241, 238)",
      color: "rgba(55, 53, 47, 0.65)",
      outline: 0,
      border: 0,
      padding: "16px 12px",
      width: "100%",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "12px",
      "& svg": {
        marginRight: "8px",
      },
    },
    "& .content-editable.empty": {
      "&:after": {
        color: placeHolderColor,
      },
    },
    "& .checkbox-edit": {
      alignSelf: "flex-start",
      "& .MuiFormControlLabel-root": {
        marginRight: "0px",
      },
      "& .MuiCheckbox-root": {
        padding: "5px",
        "& svg": {
          width: "20px",
          height: "20px",
        },
      },
    },
    "& .tools-drawer": {
      zIndex: 1300,
      "& .MuiDrawer-paper": {
        backgroundColor: theme?.palette?.editor?.background,
      },
    },
    "& .section-tw": {
      "& button": {
        padding: "2px",
        borderRadius: "0px",
        "& svg": {
          width: "17px",
          height: "18px",
          marginTop: "-1px",
        },
      },
    },
    "& .carousel-item": {
      padding: "12px 32px",
    },
    "& .slick-arrow": {
      borderRadius: "50%",
      backgroundColor: "#F8FAFF",
      textAlign: "center",
      border: "1px solid #CFD8F5",
    },
    "& .embed-image-wrpr .image-frame": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      padding: "0px",
      // pointerEvents: "none",
      "& svg": {
        width: "100%",
        height: "100%",
        "& .op-zero": {
          fillOpacity: 0,
        },
      },
    },
    "&.hideScroll": {
      overflowAnchor: "none",
      "&::-webkit-scrollbar-track": {
        background: "none !important",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "none !important",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "none !important",
      },
    },
    "& ::selection": {
      color: "black",
      background: "#EAEBFE",
    },
  },
  fullScreenWrapper: {
    "& .MuiPaper-root": {
      borderRadius: "0px !important",
      "& .MuiDialogTitle-root": {
        position: "absolute",
        top: 0,
        right: "0px",
        zIndex: 100,
      },
      "& .MuiDialogContent-root": {
        padding: "0px",
      },
    },
  },
  cardsTypo: {
    color: theme?.palette?.editor?.textColor,
    fontSize: "14px !important",
  },
});

export default editorStyles;
