export const settingsLabel = {
  text: "Text Settings",
  button: "Button Settings",
  image: "Image Settings",
  video: "Video Settings",
  box: "Box Settings",
  appHeader: "App Header Settings",
  form: "Form Settings",
};

export const ItemTypes = {
  text: "Text",
  button: "Button",
  image: "Image",
  video: "Video",
  box: "Box",
  appHeader: "App Header",
  form: "Form",
};
