import React from "react";
import { Path, Transforms, Node } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";
import { Box, useTheme } from "@mui/material";
import RnD from "../../common/RnD";
import More from "./Options/More";
import { ROW_HEIGHT } from "../../common/RnD/Utils/gridDropItem";
import AddElement from "./Options/AddElement";
import useBreakpoints from "../../hooks/useBreakpoints";
import { breakpointValues } from "./breakpointConstants";
import useFreeGridStyles from "./styles";
import { insertFreeGrid, insertFreeGridItem } from "../../utils/freegrid";
import { useEditorContext } from "../../hooks/useMouseMove";
import SectionSettings from "./Options/SectionSettings";
import scrollToNewSection from "../../helper/RnD/scrollToNewSection";
import { createEmbedNode } from "../../utils/embed";
import { onPasteRnDNode } from "../../helper";
import focusOnNewItem from "../../helper/RnD/focusOnNewItem";
import { appHeaderNode } from "../../utils/insertAppHeader";
import { FORM_NODE } from "../../utils/form";
import itemOptions from "./Options/sectionItemOptions";
const MAX_DEVICE_WIDTH = {
  lg: 980,
  xs: 320,
};

const FreeGrid = (props) => {
  const theme = useTheme();
  const breakpoint = useBreakpoints(theme);
  const classes = useFreeGridStyles({
    theme,
    MAX_DEVICE_WIDTH: MAX_DEVICE_WIDTH[breakpoint],
  });
  const editor = useSlateStatic();
  const { element, attributes, children, customProps } = props;
  const { sectionName } = element;
  const { readOnly, customSX } = customProps;
  const { updated_at, sectionBgColor, sectionBackgroundImage } = element;
  const path = ReactEditor.findPath(editor, element);
  // get values based on breakpoint size
  const { height } = breakpointValues(element.type, breakpoint, element);
  const { setSelectedElement } = useEditorContext();

  const onChange = (data) => {
    const append = breakpoint === "lg" ? "" : `_${breakpoint}`;
    const updateData = {
      ...data,
      [`height${append}`]: height + data.height,
    };
    if (append !== "") {
      delete updateData.height;
    }
    if (breakpoint === "xs") {
      updateData.xs_updatedOn = new Date().getTime();
    }
    delete updateData.children;
    Transforms.setNodes(
      editor,
      {
        ...updateData,
      },
      {
        at: path,
      }
    );
  };

  const moveUp = () => {
    const cur_root_path = Path.parent(path);
    if (Path.hasPrevious(cur_root_path)) {
      Transforms.moveNodes(editor, {
        at: cur_root_path,
        to: Path.previous(cur_root_path),
      });
    }
  };

  const moveDown = () => {
    const cur_root_path = Path.parent(path);
    Transforms.moveNodes(editor, {
      at: cur_root_path,
      to: Path.next(cur_root_path),
    });
  };

  const handleActionClick = (actionType) => {
    switch (actionType) {
      case "moveUp":
        moveUp();
        break;
      case "moveDown":
        moveDown();
        break;
      default:
    }
  };

  const handleMoreClick = (moreAction) => () => {
    try {
      const cur_root_path = Path.parent(path);
      const next_path = Path.next(cur_root_path);
      switch (moreAction) {
        case "addSection":
          insertFreeGrid(editor, next_path, { setSelectedElement });
          break;
        case "duplicateSection":
          Transforms.insertNodes(
            editor,
            [
              {
                ...JSON.parse(JSON.stringify(Node.get(editor, cur_root_path))),
              },
            ],
            {
              at: next_path,
            }
          );
          scrollToNewSection(editor, next_path, { setSelectedElement });
          break;
        default:
      }
    } catch (err) {
      console.log(err);
    }
  };

  const isEmptySection = () => {
    try {
      const emptyNode =
        element?.children?.find((f) => !f.type) &&
        element?.children.length === 1;
      return emptyNode;
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddElementClick = (type) => () => {
    const isEmpty = isEmptySection();
    const insertAt = isEmpty
      ? [...path, 0]
      : [...path, element?.children?.length];
    switch (type) {
      case "addText":
        Transforms.insertNodes(
          editor,
          [
            {
              type: "freegridItem",
              childType: "text",
              children: [
                {
                  type: "paragraph",
                  children: [{ text: "Text" }],
                },
              ],
              gridArea: "3 / 1 / 4 / 2",
              left: 50,
              marginTop: 0,
              top: 0,
              width: 170,
              height: 80,
            },
          ],
          {
            at: [...insertAt],
          }
        );
        break;
      case "addButton":
        Transforms.insertNodes(
          editor,
          [
            {
              type: "freegridItem",
              childType: "button",
              children: [
                {
                  type: "button",
                  children: [{ text: "" }],
                  buttonLink: {
                    linkType: "webAddress",
                  },
                  iconPosition: "start",
                  bgColor: "#2563EB",
                  textColor: "#FFF",
                  borderRadius: {
                    topLeft: 30,
                    topRight: 30,
                    bottomLeft: 30,
                    bottomRight: 30,
                  },
                  bannerSpacing: {
                    left: 12,
                    top: 12,
                    right: 12,
                    bottom: 12,
                  },
                },
              ],
              gridArea: "3 / 1 / 4 / 2",
              left: 50,
              marginTop: 0,
              top: 0,
              width: 170,
              height: 80,
            },
          ],
          {
            at: [...insertAt],
          }
        );
        break;
      case "addImage":
        Transforms.insertNodes(
          editor,
          [
            {
              type: "freegridItem",
              childType: "image",
              children: [
                {
                  type: "image",
                  url: "",
                  images: [],
                  children: [{ text: "" }],
                },
              ],
              gridArea: "3 / 1 / 4 / 2",
              left: 50,
              marginTop: 0,
              top: 0,
              width: 170,
              height: 80,
            },
          ],
          {
            at: [...insertAt],
          }
        );
        break;
      case "addVideo":
        Transforms.insertNodes(
          editor,
          [
            {
              ...insertFreeGridItem(
                "video",
                createEmbedNode("video", { url: "", alt: "", images: [] }),
                { height: 300, width: 250 }
              ),
            },
          ],
          {
            at: [...insertAt],
          }
        );
        break;
      case "addBox":
        Transforms.insertNodes(
          editor,
          [
            {
              ...insertFreeGridItem(
                "box",
                { type: "paragraph", children: [{ text: "" }] },
                {},
                "freegridBox"
              ),
            },
          ],
          {
            at: [...insertAt],
          }
        );
        break;
      case "addForm":
        Transforms.insertNodes(
          editor,
          [
            {
              ...insertFreeGridItem(
                "form",
                { ...FORM_NODE() },
                {
                  height: 100,
                  width: 400,
                }
              ),
            },
          ],
          {
            at: [...insertAt],
          }
        );
        break;
      case "addAppHeader":
        Transforms.insertNodes(
          editor,
          [
            {
              ...insertFreeGridItem(
                "appHeader",
                { ...appHeaderNode({ menus: null }) },
                {
                  height: 60,
                  width: 400,
                }
              ),
            },
          ],
          {
            at: [...insertAt],
          }
        );
        break;
      default:
    }
    // focus on newly added element
    focusOnNewItem(editor, insertAt, { setSelectedElement });
  };

  const onPaste = () => {
    try {
      const cur_root_path = Path.parent(path);
      const parsed_node = JSON.parse(window.copiedNode);
      if (
        parsed_node?.type === "freegridItem" ||
        parsed_node?.type === "freegridBox"
      ) {
        const np = onPasteRnDNode(editor, { path, children: children });
        if (np) {
          focusOnNewItem(editor, np, { setSelectedElement });
        }
      } else {
        // for pasting whole section
        const new_path = Path.next(cur_root_path);
        Transforms.insertNodes(editor, [{ ...parsed_node }], { at: new_path });
        scrollToNewSection(editor, new_path, { setSelectedElement });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onDelete = () => {
    try {
      Transforms.removeNodes(editor, { at: Path.parent(path) });
    } catch (err) {
      console.log(err);
    }
  };

  const handleContextMenuClick = (d) => {
    switch (d?.name) {
      case "cut":
        window.copiedNode = JSON.stringify(Node.get(editor, Path.parent(path)));
        onDelete();
        break;
      case "copy":
        window.copiedNode = JSON.stringify(Node.get(editor, Path.parent(path)));
        break;
      case "paste":
        onPaste();
        break;
      case "delete":
        onDelete();
        break;
      default:
        return;
    }
  };

  const repeatTimes = Math.floor(height / ROW_HEIGHT);

  return (
    <RnD
      id={`freegrid_container_${path.join("|")}_${updated_at}_${breakpoint}`}
      className={`freegrid-section breakpoint-${breakpoint}`}
      editor={editor}
      path={path}
      disableDragging={true}
      style={{
        position: "relative",
        "--height": `${height}px`,
      }}
      defaultStyle={{ width: "100%", height: height ? `${height}px` : "auto" }}
      enableResizing={{
        bottom: true,
        top: false,
        left: false,
        right: false,
        topLeft: false,
        topRight: false,
        bottomLeft: false,
        bottomRight: false,
      }}
      actions={itemOptions?.section || []}
      type={"parent"}
      optionsProps={{
        placement: "right",
        sx: {
          ...classes.sectionPopper,
          ...(customSX?.sectionPopper || {}),
        },
      }}
      settingsProps={{
        settings: "freeGrid",
        customProps,
      }}
      onChange={onChange}
      handleActionClick={handleActionClick}
      actionsMap={{
        addElement: {
          Component: AddElement,
          placement: "left",
          title: "Add Element",
          props: {
            handleClick: handleAddElementClick,
          },
        },
        more: {
          Component: More,
          placement: "left",
          title: "More Options",
          props: {
            handleClick: handleMoreClick,
          },
        },
        settings: {
          Component: SectionSettings,
          placement: "left",
          title: "Section Settings",
          props: {
            editor,
            path,
            classes,
          },
        },
      }}
      readOnly={readOnly}
      updated_at={updated_at}
      breakpoint={breakpoint}
      handleContextMenuClick={handleContextMenuClick}
    >
      <Box
        {...attributes}
        id={sectionName}
        data-event={"rnd-click"}
        className="freegrid-container freegrid-container-parent"
        sx={classes.root}
        data-path={path.join("|")}
        style={{
          "--cols": `100%`,
          "--rows": `repeat(${repeatTimes}, ${ROW_HEIGHT}px)`,
          background: sectionBgColor,
          backgroundImage: `url('${sectionBackgroundImage}')`,
          backgroundSize: "cover",
        }}
      >
        {children}
        {!readOnly ? (
          <span
            placeholder={`Section (${sectionName || "Welcome"})`}
            className="freegrid-section-infos"
            contentEditable={false}
          />
        ) : null}
      </Box>
    </RnD>
  );
};

export default FreeGrid;
