const boxStyle = [
  {
    tab: "Colors",
    value: "sectionColors",
    fields: [
      {
        label: "Background Color",
        key: "sectionBgColor",
        type: "color",
      },
    ],
  },
  {
    tab: "Background",
    value: "sectionBackgroundImage",
    fields: [
      {
        label: "Background Image URL",
        key: "sectionBackgroundImage",
        type: "text",
      },
      {
        label: "Background Image",
        key: "sectionBackgroundImage",
        type: "backgroundImage",
      },
    ],
  },
  {
    tab: "Border",
    value: "sectionBorderRadius",
    fields: [
      {
        label: "Border",
        key: "sectionBorderRadius",
        type: "borderRadius",
      },
    ],
  },
];

export default boxStyle;
