import React from "react";
import useElementSettingsStyle from "./styles";
import OtherSettings from "./OtherSettings";

const ElementSettings = (props) => {
  const { currentAction } = props;
  const classes = useElementSettingsStyle();
  const Settings = OtherSettings[currentAction];

  return Settings ? <Settings classes={classes} {...props} /> : null;
};
ElementSettings.defaultProps = {};
export default ElementSettings;
