const useContextMenuStyle = () => ({
  root: {
    zIndex: 1200,
    "& .cm-menu-item": {
      width: "190px",
      justifyContent: "space-between",
      "& .shortcut-help-info": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "12px",
        color: "#bbb",
        "& svg": {
          width: "12px",
          marginRight: "4px",
        },
      },
    },
  },
});

export default useContextMenuStyle;
