import React from "react";
import { Paper, Popper } from "@mui/material";
import useDragInfoStyle from "./styles";

const DragInfo = (props) => {
  const { open, dragging, anchorEl } = props;
  const { position } = dragging;
  const { diffX } = position || {};
  const classes = useDragInfoStyle();
  const x = parseInt(position?.x - window.innerWidth / 2 + 490 - diffX);
  return open ? (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement="top-start"
      sx={classes.root}
    >
      <Paper className="papper-root">
        x: {x}, y: {position?.y}
      </Paper>
    </Popper>
  ) : null;
};

export default DragInfo;
