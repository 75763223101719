import React from "react";
import { Transforms, Node, Path } from "slate";
import { useSlateStatic } from "slate-react";
import { ReactEditor } from "slate-react";
import { Box, useTheme } from "@mui/material";
import RnD from "../../common/RnD";
import { onDropItem, ROW_HEIGHT } from "../../common/RnD/Utils/gridDropItem";
import useBreakpoints from "../../hooks/useBreakpoints";
import {
  breakpointValues,
  formatBreakpointValues,
} from "./breakpointConstants";
import { useEditorContext } from "../../hooks/useMouseMove";
import { getBreakPointsValue } from "../../helper/theme";
import focusOnNewItem from "../../helper/RnD/focusOnNewItem";
import { bringItemToFB } from "../../helper";
import itemOptions from "./Options/sectionItemOptions";

const FreeGridBox = (props) => {
  const theme = useTheme();
  const breakpoint = useBreakpoints(theme);
  const { element, attributes, children, customProps } = props;
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);
  const { readOnly } = customProps;
  const {
    updated_at,
    zIndex,
    sectionBgColor,
    sectionBackgroundImage,
    sectionBorderRadius,
  } = element;
  // get values based on breakpoint size
  const { gridArea, width, height, left, top, marginTop } = breakpointValues(
    element.type,
    breakpoint,
    element
  );
  const { setSelectedElement } = useEditorContext();
  const arrangeIndex = zIndex === undefined ? path[path.length - 1] : zIndex;

  const onChangeSettings = () => {};

  const onChange = (data) => {
    let updateData = { ...data };
    delete updateData.children;
    updateData = formatBreakpointValues(element.type, breakpoint, updateData);
    Transforms.setNodes(
      editor,
      {
        ...updateData,
      },
      {
        at: path,
      }
    );
  };

  const handleDragEvent = (type, data, cb) => {
    const toPosition = {
      left: left + data.lastX,
      top: top + data.lastY,
    };
    const { node, ...rest } = data;
    switch (type) {
      case "stop":
        const updated_data = onDropItem(
          {
            editor,
            fromPosition: { left, top },
            toPosition,
            path,
            ref: node,
            breakpoint,
            ...rest,
          },
          ".freegrid-container"
        );
        if (cb) {
          cb(updated_data);
        }
        break;
      default:
    }
  };

  const handleResizeEvent = (type, data, cb) => {
    switch (type) {
      case "stop":
        return;
      default:
    }
  };

  const onDelete = () => {
    try {
      Transforms.removeNodes(editor, { at: path });
    } catch (err) {
      console.log(err);
    }
  };

  const handleContextMenuClick = (d) => {
    switch (d?.name) {
      case "bringForward":
        bringItemToFB(editor, { path, moveFront: true });
        Transforms.setNodes(
          editor,
          { zIndex: Math.min(arrangeIndex + 1, editor.children.length) },
          { at: path }
        );
        break;
      case "bringBackward":
        bringItemToFB(editor, { path, moveFront: false });
        break;
      case "cut":
        window.copiedNode = JSON.stringify(Node.get(editor, path));
        onDelete();
        focusOnNewItem(editor, Path.parent(path), {
          setSelectedElement,
        });
        break;
      case "copy":
        window.copiedNode = JSON.stringify(Node.get(editor, path));
        break;
      case "delete":
        onDelete();
        setSelectedElement({});
        break;
      default:
        return;
    }
  };

  const repeatTimes = Math.floor(height / ROW_HEIGHT);

  return (
    <RnD
      id={`freegrid_box_item_${path.join("|")}_${updated_at}_${breakpoint}`}
      className={`freegrid-item path-${path.length} breakpoint-${breakpoint}`}
      editor={editor}
      path={path}
      actions={itemOptions?.box}
      settingsProps={{
        settings: "freegridBoxItem",
        onChange: onChangeSettings,
        customProps,
      }}
      style={{
        "--left": `${left || 0}px`,
        "--marginTop": `${marginTop || 0}px`,
        "--gridArea": `${gridArea}`,
        "--width": `${width}px`,
        "--height": `${height}px`,
        "--zIndex": 100 + arrangeIndex,
      }}
      defaultStyle={{
        height: `${height}px`,
        width: `${width}px`,
      }}
      gridArea={gridArea}
      onChange={onChange}
      delta={{ width, height }}
      handleDragEvent={handleDragEvent}
      handleResizeEvent={handleResizeEvent}
      readOnly={readOnly}
      childType={"box"}
      handleContextMenuClick={handleContextMenuClick}
      itemData={{ left: left || 0 }}
      type={"parent-container"}
    >
      <Box
        component={"div"}
        {...attributes}
        className={"fgi_type_box freegrid-container-parent"}
        data-path={path.join("|")}
        style={{
          "--height": `${height}px`,
          "--cols": `100%`,
          "--rows": `repeat(${repeatTimes}, ${ROW_HEIGHT}px)`,
        }}
        sx={{
          borderRadius: {
            ...getBreakPointsValue(
              sectionBorderRadius || {},
              null,
              "overrideBorderRadius",
              true
            ),
          },
          background: sectionBgColor,
          backgroundImage: `url(${sectionBackgroundImage})`,
        }}
      >
        {children}
      </Box>
      {/* <Box className={"debug-info"} contentEditable={false}>
        <b>Debug Info:</b> <br />
        Path: {path.join("|")} <br />
        Grid Area: {gridArea}
      </Box> */}
    </RnD>
  );
};

export default FreeGridBox;
