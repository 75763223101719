const useElementSettingsStyle = (theme) => ({
  root: {
    zIndex: 1200,
    background: "#FFF",
    borderRadius: "10px",
    overflow: "hidden",
    boxShadow: "0px 0px 12px 4px #00000014",
    padding: "10px 16px",
    "& .papper-root": {
      width: "312px",
      boxShadow: "none",
    },
    ".MuiDivider-root": {
      background: "#DCE4EC",
    },
    "& .closeBtn": {
      borderRadius: "4px",
      width: "26px",
      height: "26px",
      background: "#F8FAFC",
      padding: "7px",
    },
    ".MuiAccordionSummary-root": {
      minHeight: "40px !important",
      padding: "0px",
    },
    ".MuiAccordionSummary-content": {
      margin: "0px",
    },
    "& .MuiAccordion-root": {
      boxShadow: "none",
      "& .accordionTitle": {
        display: "flex",
        alignItems: "center",
      },
      "& .expandIcon": {
        transform: "rotate(270deg)",
        transition: "all 0.5s",
        fill: "#64748B",
      },
      "&.Mui-expanded": {
        "& .expandIcon": {
          transform: "rotate(360deg)",
        },
      },
    },
    "& .item-w": {
      maxHeight: "300px",
      overflowX: "hidden",
      overflowY: "auto",
    },
    "& .item-wrapper": {
      fontFamily: "sans-serif",
      padding: "5px 0px 12px 0px",
      "& .text-formatter-popup": {
        padding: "0px",
        overflowX: "hidden",
      },
      "&.title": {
        display: "flex",
        fontSize: "16px",
        fontWeight: "bold",
        justifyContent: "space-between",
        alignItems: "center",
        "& .MuiIconButton-root": {
          padding: "4px",
          "& svg": {
            width: "16px",
            height: "16px",
          },
        },
      },
    },
  },
  dialog: {
    "& .MuiDialogTitle-root": {
      display: "flex",
      justifyContent: "space-between",
    },
    "& .MuiGrid-root": {
      marginBottom: "12px",
    },
    "& .action-btn-wrpr": {
      justifyContent: "flex-end !important",
      "& .savebtn": {
        marginRight: "12px",
      },
      "& .close": {
        marginRight: "12px",
      },
    },
  },
});

export default useElementSettingsStyle;
