import React from "react";
import { Box, List, ListItemButton } from "@mui/material";

const AddElement = (props) => {
  const { handleClick } = props;
  return (
    <Box>
      <List className="item-list-wrpr">
        <ListItemButton
          className="item-wrapper"
          onClick={handleClick("addText")}
        >
          Text
        </ListItemButton>
        <ListItemButton
          className="item-wrapper"
          onClick={handleClick("addButton")}
        >
          Button
        </ListItemButton>
        <ListItemButton
          className="item-wrapper"
          onClick={handleClick("addImage")}
        >
          Image
        </ListItemButton>
        <ListItemButton
          className="item-wrapper"
          onClick={handleClick("addVideo")}
        >
          Video
        </ListItemButton>
        <ListItemButton
          className="item-wrapper"
          onClick={handleClick("addBox")}
        >
          Box
        </ListItemButton>
        <ListItemButton
          className="item-wrapper"
          onClick={handleClick("addForm")}
        >
          Form
        </ListItemButton>
        <ListItemButton
          className="item-wrapper"
          onClick={handleClick("addAppHeader")}
        >
          App Header
        </ListItemButton>
      </List>
    </Box>
  );
};

export default AddElement;
