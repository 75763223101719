import React from "react";
import { Transforms, Node } from "slate";
import { Box } from "@mui/material";
import { StyleContent } from "../../../common/StyleBuilder";
import sectionStyle from "../../../common/StyleBuilder/sectionStyle";

const SectionSettings = (props) => {
  const { editor, path, classes } = props;
  const element = Node.get(editor, path);
  const styleMaps = sectionStyle?.filter((f) => !f?.hideOnFGS);

  const onChange = (data) => {
    const updated_props = {
      ...element,
      ...data,
      field_type: data?.element,
    };
    delete updated_props.children;
    Transforms.setNodes(
      editor,
      {
        ...updated_props,
      },
      {
        at: path,
      }
    );
  };

  const handleClose = () => {
    console.log("close");
  };

  return (
    <Box
      component={"div"}
      className="style-settings-wrpr"
      sx={classes.sectionSettingsPopper}
    >
      {styleMaps?.map((m, i) => {
        return (
          <StyleContent
            key={`tab_${m.value}_$${i}`}
            renderTabs={sectionStyle}
            value={m.value}
            element={element}
            onChange={onChange}
            customProps={{}}
            handleClose={handleClose}
          />
        );
      })}
    </Box>
  );
};

export default SectionSettings;
