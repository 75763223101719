const useCommonStyle = (theme) => ({
  sideBarDrawer: {
    "& .MuiPaper-root": {
      backgroundColor: theme?.palette?.editor?.background,
    },
    "& .MuiTypography-root": {
      color: theme?.palette?.editor?.textColor,
    },
    "& .MuiInputBase-input": {
      color: theme?.palette?.editor?.textColor,
    },
    "& .MuiCheckbox-root": {
      color: theme?.palette?.editor?.textColor,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme?.palette?.editor?.borderColor}`,
    },
    "& .close-popupbtn": {
      color: theme?.palette?.editor?.textColor,
    },
  },
  sapcingInput: {
    backgroundColor: theme?.palette?.editor?.background,
    color: theme?.palette?.editor?.textColor,
  },
  colorPickerPopup: {
    "& .MuiPaper-root": {
      overflow: "auto",
      backgroundColor: theme?.palette?.editor?.background,
    },
    "& .popup_tabs": {
      backgroundColor: theme?.palette?.editor?.background,
    },
    "& .popup_tabs-header": {
      backgroundColor: theme?.palette?.editor?.background,
      "& .popup_tabs-header-label-active": {
        color: theme?.palette?.editor?.activeColor,
        backgroundColor: theme?.palette?.editor?.background,
      },
    },
    "& .colorpicker": {
      backgroundColor: theme?.palette?.editor?.background,
      "& .default-color-panel": {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, 20px)",
        gridGap: "3px",
        justifyContent: "space-between",
        margin: "14px -16px 0px -16px",
        padding: "2px 16px 0px 16px",
        overflow: "auto",
        maxHeight: "105px",
        "& .default-color-panel_item": {
          height: '20px',
          width: '20px',
          border: '1px solid #E2E8F0'
        }
      },
    },
    "& .color-picker-panel": {
      backgroundColor: theme?.palette?.editor?.background,
    },
    "& .input_rgba": {
      "& input": {
        backgroundColor: theme?.palette?.editor?.background,
        color: theme?.palette?.editor?.textColor,
      },
    },
  },
  signaturePopup: {
    "& .MuiPaper-root": {
      backgroundColor: theme?.palette?.editor?.background,
      "& .signature-canvas": {
        backgroundColor: "#FFF",
      },
      "& label": {
        color: theme?.palette?.editor?.textColor,
      },
      "& input": {
        color: theme?.palette?.editor?.textColor,
        backgroundColor: theme?.palette?.editor?.background,
      },
      "& .react-datepicker__input-container": {
        "& svg": {
          fill: theme?.palette?.editor?.textColor,
        },
      },
      "& fieldset": {
        borderColor: theme?.palette?.editor?.textColor,
      },
      "& .upload-wrapper-ui": {
        "& .MuiTypography-root": {
          color: theme?.palette?.editor?.textColor,
        },
      },
      "& .typesignature-fontfamily": {
        "& button": {
          color: theme?.palette?.editor?.textColor,
        },
      },
    },
  },
  popupTitle2: {
    fontWeight: "600 !important",
    fontSize: "20px",
    display: "flex",
    alignItems: "center",
  },
  textOptions: {
    "& .MuiPopover-root": {
      backgroundColor: theme?.palette?.editor?.background,
    },
  },
});

export default useCommonStyle;
