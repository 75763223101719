import React, { useState } from "react";
import { Paper, Popper, Tooltip } from "@mui/material";
import Actions from "./Actions";
import useElementOptionsStyle from "./styles";
import OptionsPopup from "../OptionsPopup";

const ElementOptions = (props) => {
  const {
    open,
    anchorEl,
    enable,
    handleAction,
    actions,
    actionsMap,
    optionsProps,
    selectedAction,
  } = props;
  const classes = useElementOptionsStyle();
  const [menu, setMenu] = useState({});
  const openMenu = Boolean(menu.anchorEl);
  const { MenuComp, menuProps } = menu;
  const isOpen = Boolean(anchorEl) && open;

  const handleOptionClick = (type) => (e) => {
    if (actionsMap[type]) {
      const { title, placement, Component, props } = actionsMap[type];
      setMenu({
        title,
        type,
        anchorEl: e.currentTarget,
        placement,
        isComponent: true,
        MenuComp: Component,
        menuProps: props,
      });
    } else {
      handleAction(type);
    }
  };

  const renderAction = (action, index) => {
    const { Button, Icon, type, title } = Actions[action] || {};
    return type ? (
      <Tooltip title={title} arrow key={`actions_fgele_${type}_${index}`}>
        <Button
          onClick={handleOptionClick(type)}
          className={enable === type || selectedAction === type ? "active" : ""}
        >
          {Icon ? <Icon /> : title}
        </Button>
      </Tooltip>
    ) : null;
  };

  const handleClose = () => {
    setMenu({});
  };

  return isOpen ? (
    <>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="top-start"
        sx={classes.root}
        {...optionsProps}
        contentEditable={false}
        disablePortal={false}
      >
        <Paper className="papper-root">{actions.map(renderAction)}</Paper>
      </Popper>
      {open && MenuComp ? (
        <OptionsPopup open={openMenu} {...menu} handleClose={handleClose}>
          <MenuComp {...menuProps} />
        </OptionsPopup>
      ) : null}
    </>
  ) : null;
};
ElementOptions.defaultProps = {
  actions: [],
};
export default ElementOptions;
