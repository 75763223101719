import { Node, Transforms } from "slate";

const updateAutoProps = (editor, datas = [], breakpoint = "") => {
  try {
    for (let i = 0; i < datas.length; i++) {
      const { path, props } = datas[i] || {};
      const int_path = path.split("|").map((m) => parseInt(m));
      const updatedProps = Object.keys(props).reduce((a, b) => {
        const key = breakpoint ? `${b}_${breakpoint}` : b;
        a[key] = props[b];
        return a;
      }, {});
      const oldProps = Node.get(editor, int_path);
      if (!oldProps?.xs_updatedOn) {
        Transforms.setNodes(editor, { ...updatedProps }, { at: int_path });
      }
    }
  } catch (err) {
    console.log(err);
  }
};

export default updateAutoProps;
