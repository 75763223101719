import React, { useEffect } from "react";
import { Editor, Transforms } from "slate";
import TextFormat from "../../../../Toolbar/PopupTool/TextFormat";
import usePopupStyle from "../../../../Toolbar/PopupTool/PopupToolStyle";
import { ReactEditor } from "slate-react";

const TextSettings = (props) => {
  const { editor, path } = props;
  const item_path = path.split("|").map((m) => parseInt(m));
  const element_path = [...item_path, 0];
  const classes = usePopupStyle();

  useEffect(() => {
    try {
      ReactEditor.focus(editor);
      const range = Editor.range(editor, element_path);
      Transforms.select(editor, range);
      ReactEditor.focus(editor);
    } catch (err) {
      console.log(err);
    }
  }, []);

  return <TextFormat classes={classes} editor={editor} />;
};

export default TextSettings;
