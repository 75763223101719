import { withReact } from "slate-react";
import { withHistory } from "slate-history";
import withLinks from "../plugins/withLinks";
import withTables from "../plugins/withTable";
import withEmbeds from "../plugins/withEmbeds";
import withEquation from "../plugins/withEquation";
import withMentions from "../plugins/withMentions";
import withLayout from "../plugins/withLayout";
import withHtml from "../plugins/withHTML";
import withErrorHandling from "./withErrorHandling";
import withCustomDeleteBackward from "../plugins/withCustomDeleteBackward";

const withCommon = (props, rest = {}) => {
  return rest.needLayout
    ? withErrorHandling(
        withHtml(
          withEquation(
            withLayout(
              withHistory(
                withEmbeds(
                  withTables(
                    withLinks(
                      withMentions(withCustomDeleteBackward(withReact(props)))
                    )
                  )
                )
              )
            )
          )
        )
      )
    : withErrorHandling(
        withHtml(
          withEquation(
            withHistory(
              withEmbeds(
                withTables(
                  withLinks(
                    withMentions(withCustomDeleteBackward(withReact(props)))
                  )
                )
              )
            )
          )
        )
      );
};

export default withCommon;
